#contact-us-page-content {
    max-width: 100%;
    margin: 0px !important;
    background-color: #eee;
}
.contact-us-wrapper {
    display: flex;
    flex-direction: row;
    margin: 40px auto;
    max-width: 1150px;
    min-height: 479px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px #cacaca;
    background-color: var(--salsa-orange);
}
.contact-form-wrapper {
    box-sizing: border-box;
    width: 50%;
    padding: 20px 40px 40px;
    background-color: white;
}
/* FORM STYLIZATION */
.contact-form-wrapper h1 {
    margin: 0 auto 10px auto;
    font-size: 34px;
    font-weight: 600;
}
#contact-us-form {
    display: flex;
    flex-direction: column;
}
.form-single-line-input {
    box-sizing: border-box;
    margin: 5px auto 10px;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid grey;
}
.form-box-line-input {
    box-sizing: border-box;
    margin: 10px auto 10px;
    padding: 10px;
    width: 100%;
    border: none;
    font-size: 16px;
    font-family: sans-serif;
    background-color: #eee;
}
.form-submit-btn {
    width: 100%;
    margin: 10px auto 0px;
    padding: 5px 10px 10px;
    font-size: 20px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: 0px;
    font-family: 'sofia-pro';
    background: var(--salsa-orange);
}
.form-submit-btn:hover {
    background: #c94202;
}
/*  */
div#form-submmission-message {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
div#form-submmission-message h1 {
    margin: 20px auto;
}
div#form-submmission-message p {
    text-align: center;
}
#form-submmission-message svg {
    color: #1cad1c;
    font-size: 200px;
}
/********************/
.contact-us-social-wrapper {
    box-sizing: border-box;
    width: 50%;
    padding: 20px;
    margin: auto;
    text-align: center;
}
.contact-us-social-wrapper svg {
    position: relative;
    top: 2px;
    color: white;
}
.contact-us-phone {
    padding-left: 20px;
    font-size: 20px;
    color: white;
}
.contact-us-social-title {
    width: 100%;
    margin: 10px auto 10px;
    padding-top: 10px;
    font-size: 20px;
    font-family: 'sofia-pro';
    color: white;
    border-top: 1px solid #fff;
}
.contact-us-social-wrapper a {
    color: white;
    font-size: 40px;
    margin: auto 10px;
}
.contact-us-social-wrapper a:hover {
    color: #1cbefc;
}

@media screen and (max-width: 980px) {
    .contact-us-wrapper {
        flex-direction: column;
    }
    .contact-form-wrapper {
        width: 100%;
    }
    .contact-us-social-wrapper {
        width: 100%;
    }
}

@media screen and (max-width:768px) {
    .contact-us-wrapper {
        margin: -20px;
    }
    .contact-form-wrapper {
        padding: 20px;
    }
}
@media screen and (max-width: 580px) {
    .contact-us-wrapper {
        margin: -10px;
    }
  }