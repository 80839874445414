.community-partners-page-banner {
    padding-top: 31.5%;
    background-position: center;
    background-size: contain;
    background-image: url(../Media/Slides/CommunityPartners_Slider/SliderImages_0001_Salsa-on-St-Clair-Crowd.jpg);
}
.mobile-title {
    display: none;
}
.hovercard-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}
.hovercard {
    position: relative;
    width: calc(33% - 15px);
    height: 350px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgb(205 205 205);
}
.hovercard-front {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: white;
    z-index: 1;
    overflow: hidden;
}
.hovercard-front img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    object-fit: contain;
}
.hovercard-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transform: rotateY(180deg);
    background-color: white;
    position: absolute;
    padding: 20px 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.info-title {
    margin: 0px auto 10px;
    font-size: 18px;
}
p.info-description {
    margin: 0px auto;
}
.hovercard:hover .hovercard-front {
    transition-delay: 250ms;
    animation: front-horizontal-flip ease-in-out 500ms forwards;
    z-index: -1;
}
.hovercard:hover .hovercard-back {
    transition-delay: 250ms;
    animation: back-horizontal-flip ease-in-out 500ms forwards;
    z-index: 1;
}

seperator {
    display: block;
    width: 100%;
    height: 2px;
    margin: 40px auto;
    background: rgb(205 205 205);
}

.hovercard:hover {
    perspective: 500px;
}

/* Hover over lay on mobile */
.hovercard-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100%;
    z-index: 10;
    background: #000000ad;
}
.hovercard-content {
    position: relative;
    width: 90%;
    max-width: 300px;
    padding: 20px;
    background: white;
}
.hovercard-overlay-info {
    pointer-events: none;
}
.close-hovercard {
    position: absolute;
    top: -5px;
    right: 5px;
    font-size: 28px;
    margin: auto;
    text-align: right;
    color: black;
    cursor: pointer;
}


@keyframes front-horizontal-flip {
    to {
        transform: rotateY(180deg);
    }
}
@keyframes back-horizontal-flip {
    to {
        transform: rotateY(360deg);
    }
}
@media screen and (min-width: 850px) {
    .hovercard-front img {
        padding: 40px;
    }
    .hovercard-back {
        padding: 20px;
    }
    .hovercard-back h1 {
        font-size: 20px;
    }
}
@media screen and (max-width: 768px) {
    .desktop-title {
        display: none;
    }
    .mobile-title {
        display: block;
        font-size: 18px;
    }
    .hovercard {
        width: 33%;
        height: fit-content;
        margin: 5px;
        padding: 0px;
        box-shadow: none;
    }
    .hovercard-front img {
        height: 30vw;
        max-height: 200px;
        padding: 0px;
    }
    .hovercard-back {
        display: none;
    }
    .hovercard:hover .hovercard-front {
        transition-delay: unset;
        animation: unset;
        z-index: 1;
    }
    .hovercard:hover .hovercard-back {
        transition-delay: unset;
        animation: unset;
        z-index: -1;
    }
}
