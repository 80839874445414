@font-face {
  font-family: 'Lemonmilk-Bold';
  src: url('./Styles/Fonts/LEMONMILK-Bold.otf') format('opentype');
  /* Add additional font file formats if necessary */
  font-weight: bold; /* Use 'bold' instead of 'normal' for a bold font */
  font-style: normal; /* Use 'italic' for italic style if applicable */
}

:root {
	/*Primary Brand Palette*/
	--salsa-summer-blue:#2a0073;	 /*42, 0, 115*/
	--salsa-yellow: 	#ffbe0c;	 /*255, 190, 12*/
	--salsa-watermelon: #e01e45;	 /*224, 30, 69*/
	--salsa-sky-blue: 	#3986ff;	 /*57, 134, 255*/
	--salsa-orange: 	#fb5607;	 /*251, 86, 7*/
	--salsa-purple: 	#8338ec;	 /*131, 56, 236*/
	--salsa-white: 	    #ffffff;	 /*255, 255, 255*/
	
	/*Gradients*/
	--salsa-gradient-1: linear-gradient(135deg, rgba(255,190,12,1) 0%, rgba(131,56,236,1) 100%); /*#ffbe0c + #8338ec*/
	--salsa-gradient-2: linear-gradient(135deg, rgba(224,30,69,1) 0%, rgba(131,56,236,1) 100%);  /*#e01e45 + #8338ec*/
	--salsa-gradient-3: linear-gradient(135deg, rgba(255,190,12,1) 0%, rgba(42,0,115,1) 100%);   /*#ffbe0c + #2a0073*/
	--salsa-gradient-4:	linear-gradient(135deg, rgba(255,190,12,1) 0%, rgba(224,30,69,1) 100%);  /*#ffbe0c + #e01e45*/
	--salsa-gradient-5: linear-gradient(135deg, rgba(42,0,115,1) 0%, rgba(224,30,69,1) 100%);    /*#2a0073 + #e01e45*/
	--salsa-gradient-6: linear-gradient(135deg, rgba(224,30,69,1) 0%, rgba(57,134,255,1) 100%);  /*#e01e45 + #3986ff*/
	--salsa-gradient-7: linear-gradient(135deg, rgba(251,86,7,1) 0%, rgba(131,56,236,1) 100%);   /*#fb5607 + #8338ec*/

}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lemonmilk-Bold';
}

p, span, a {
  font-family: 'sofia-pro';
}
.title {
  margin: 20px 0px;
  font-size: 26px;
  font-weight: 500;
  text-transform: capitalize;
}
.sub-title {
  margin: 20px 0px;
  font-size: 22px;
  font-weight: 500;
  text-transform: capitalize;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page-content {
  max-width: 1400px;
  margin: 20px auto;
  padding: 20px;
}

@media screen and (max-width: 1440px) {
  .page-content {
    margin: auto;
    padding: 20px;
  }
}

@media screen and (max-width: 580px) {
  .page-content {
    padding: 10px;
  }
  .title {
    font-size: 22px;
  }
  .sub-title {
    font-size: 18px;
}
}