#homepage-content {
    max-width: 100vw !important;
    padding: 0px !important;
    margin: auto !important;
}

.gallery-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.gallery-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
    text-align: center;
    color: white;
    background: #00000080;
}

.gallery-content-wrapper h1 {
    font-size: 4.5vw;
    margin: 0px auto 20px;
    text-shadow: 2px 3px 0px black;
}

.gallery-content-wrapper p {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto 30px;
    font-size: 26px;
    font-weight: 600;
    text-shadow: 2px 3px 0px black;
}
.call-to-action-link {
    text-decoration: none;
}
.call-to-action-link span {
    display: block;
    padding: 5px 20px 10px;
    color: white;
    font-size: 26px;
    font-weight: 500;
    border-radius: 5px;
    background: var(--salsa-orange)
}
.call-to-action-link span:hover {
    background: #c94202;
}
.gallery {
    width: 100%;
    height: 90vh;
    box-sizing: border-box;
}
.gallery_column {
    display: flex;
    flex-direction: column;
    width: calc(25%);
    padding-left: 2.5px;
    padding-right: 2.5px;
    float: left;
}

.gallery_img_wrapper img {
    width: 100%;
}

@media screen and (min-width: 480px) {
    .mobile-break {
        display: none;
    }
}

@media screen and (min-height: 1300px) {
    .gallery {
        height: 70vh;
    }
}

@media screen and (max-width: 1300px) {
    .gallery_column {
        width: calc(33.3%);
    }
    #column_3 {
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        gap: 5px;
    }
    #column_3 div {
        width: 33.3%;
    }
}

@media screen and (max-width: 768px) {
    .gallery-content-wrapper h1 {
        margin: 0px auto 10px;
    }
    .gallery-content-wrapper p {
        font-weight: 400;
        text-shadow: 1px 1px 0px black;
    }
    .gallery-content-wrapper p {
        width: 100%;
    }
    .gallery {
        height: 75vh;
    }
}
@media screen and (max-width: 580px) {
    .gallery-content-wrapper {
        padding: 15px;
    }
    .gallery {
        width: 110%;
        height: 65vh;
    }
    .gallery-content-wrapper h1 {
        font-size: 30px;
    }
    .gallery-content-wrapper p {
        font-size: 16px;
    }
}