.nav-wrapper {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
    background-color: var(--salsa-summer-blue);
    box-shadow: 0 0px 10px 0px #000000;
}
.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.5vw;
    margin: auto;
    height: max(5.8vw, 70px);
}
.nav a {
    height: 100%;
}
.nav-logo {
    height: 100%;
}
.desktop-menu {
    padding: 0px;
}
.desktop-menu a {
    color: white;
    text-decoration: none;
}
.desktop-menu a {
    font-size: max(1.2vw, 16px);
    font-family: 'Lemonmilk-Bold';
    padding: 1vw;
    cursor: pointer;
}
.desktop-menu a:hover {
    border-bottom: 0.4vh solid;
}
/* Mobile Menu */
#mobile-menu-wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: #11222e;
}
.mobile-toggle {
    transition: max-height 0.3s ease;
    max-height: 0;
    overflow: hidden;
}
.mobile-toggle.open {
    max-height: 210px; /* Set the desired expanded height */
}
#nav-hambuger {
    display: none;
    cursor: pointer;
    font-size: x-large;
}
.mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: auto;
}
.mobile-menu a {
    font-family: 'Lemonmilk-Bold';
    padding: 10px;
    text-decoration: none;
    cursor: pointer;
    color: var(--salsa-white);
}
.mobile-menu a:hover {
    color: #1cbefc;
}

@media screen and (min-width: 768px) {
    .mobile-menu {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .nav {
        position: relative;
        z-index: 2;
        justify-content: space-between;
        padding: 10px 20px 10px 10px;
        box-shadow: 0px 0px 5px grey;
    }
    .desktop-menu {
        display: none;
    }
    /* Mobile Menu */
    #nav-hambuger {
        display: block;
        color: var(--salsa-white);
    }
}