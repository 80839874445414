.volunteer-page-banner {
    padding-top: 31.5%;
    background-position: center;
    background-size: contain;
    background-image: url(../Media/Slides/Volunteer_Slider/volunteerBanner.webp);
}
.volunteer-bullet-wrapper {
    padding: 10px 20px 10px 0px;
    border-left: 5px solid var(--salsa-orange);
    background: #ebf1f5;
}
.volunteer-application-button {
    display: block;
    width: fit-content;
    padding: 5px 10px 10px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    border: 0px;
    text-decoration: none;
    background: var(--salsa-orange);
}
.volunteer-application-button:hover {
    background: #c94202;
}
/*****************************/
/*****TESTIMONIAL SECTION*****/
/*****************************/
section#testimonial-section {
    padding: 20px 20px 40px;
    overflow: hidden;
    background: var(--salsa-summer-blue);
}
#testimonial-title{
    max-width: 1360px;
    margin: 0px auto 20px;
    color: white;
}
.testimonials-viewport {
    max-width: 1360px;
    width: 100%;
    margin: auto;
    overflow-x: scroll;
    /* scrollbar-color: var(--salsa-sky-blue) white; */
    scroll-snap-type: x;
}
.testimonials-viewport::-webkit-scrollbar {
    margin: 10px;
    width: 12px;               /* width of the entire scrollbar */
  }
.testimonials-viewport::-webkit-scrollbar-track {
    background: white;        /* color of the tracking area */
    border-radius: 20px;
  }
.testimonials-viewport::-webkit-scrollbar-thumb {
    background-color: var(--salsa-sky-blue);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
}
.testimonials-wrapper {
    padding: 0px 0px 20px;
    display: flex;
    flex-direction: row;
    width: 700%;
}
.testimonial-wrapper {
    width: 30%;
    height: fit-content;
    margin: 10px;
    border-radius: 3px;
    background: white;
    overflow: hidden;
    scroll-snap-align: center;
    box-shadow: 0px 0px 20px 2px #00000047;
}
.testimonal-quote-wrapper {
    position: relative;
    padding: 40px;
    min-height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-quote {
    position: absolute;
    top: 15px;
    left: 15px;
    color: var(--salsa-orange);
    z-index: 2;
}
.right-quote {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: var(--salsa-orange);
    z-index: 2;
}
.testimonal-quote-wrapper p {
    position: relative;
    font-style: italic;
    z-index: 2;
}

.testimonial-name-wrapper {
    position: relative;
    text-align: center;
    color: white;
}
.wave {
    position: absolute;
    height: 80px;
    width: 70%;
    border-radius: 100%;
}
svg.avatar {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    color: var(--salsa-sky-blue);
    background: white;
    border-radius: 70%;
    font-size: 60px;
    z-index: 3;
}
#wave-1 {
    right: -15%;
    bottom: -20px;
    background-color: white;
    z-index: 1;
}
#wave-2 {
    top: -20px;
    left: -15%;
    background-color: var(--salsa-sky-blue);
    z-index: 2;
}
.testimonial-name-wrapper {
    padding: 40px 20px 20px;
    background-color: var(--salsa-sky-blue);
}
.testimonial-name-wrapper h1 {
    position: relative;
    margin: auto;
    color: white;
    z-index: 2;
}

@media screen and (max-width: 768px) {
    .testimonials-wrapper {
        width: 500%;
    }
}
@media screen and (max-width: 580px) {
    section#testimonial-section {
        margin-top: 20px;
        padding: 20px 10px 40px;
    }
    .testimonials-viewport {
        width: 100vw;
        margin: 0px -10px;
    }
    .testimonials-viewport::-webkit-scrollbar {
        display: none;
    }
    .testimonials-wrapper {
        width: 640%;
    }
    .testimonial-wrapper {
        margin: 5px;
        box-shadow: 0px 0px 6px #818181;
    }
    .testimonal-quote-wrapper {
        min-height: 300px;
        padding: 0px 20px 20px;
    }
    .testimonal-quote-wrapper p {
        font-size: 14px;
    }
    .left-quote {
        top: 7px;
        left: 7px;
        font-size: 10px;
    }
    .right-quote {
        right: 10px;
        bottom: 20px;
        font-size: 10px;
    }
}