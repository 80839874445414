.job-section {
    position: relative;
}
.location-title {
    position: relative;
    top: 20px;
    width: 300px;
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    border-radius: 0px 20px;
    color: white;
    background: var(--salsa-orange);
    box-shadow: 0px 0px 8px rgb(205 205 205);
}
.job-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px 20px 20px;
    background: #ebf1f5;
}
.job-link {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    width: calc(33.33% - 20px);
    padding: 20px 15px;
    margin: 10px;
    text-decoration: none;
    color: black;
    background: white;
    box-shadow: 0px 0px 8px rgb(205 205 205);
}
.job-link:hover {
    color: white !important;
    background: #5a5a5a;
}
.job-item {
    text-align: center;
}
.job-title {
    margin: auto;
    font-family: 'sofia-pro';
    font-size: 20px;
}

/*****Mobile Tab******/
.active-tab {
    color: var(--salsa-orange) !important;
    background: white !important;
}
.active-wrapper {
    display: block !important;
}
.mobile-wrapper {
    display: none;
}
.mobile-tab-wrapper {
    position: relative;
    top: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 20px;
    box-shadow: 0px 0px 8px rgb(205 205 205);
    overflow: hidden;
}
.mobile-tab {
    width: 50%;
    padding: 10px;
    margin: auto;
    font-size: 18px;
    text-align: center;
    color: white;
    background: var(--salsa-orange);
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .job-link {
        width: calc(50% - 20px);
    }

}

@media screen and (max-width: 580px) {
    .mobile-wrapper {
        display: block;
    }
    .location-title {
        display: none;
    }
    .job-wrapper {
        padding: 20px 10px 10px;
    }
    .job-link {
        width: 100%;
        min-height: 100px;
        margin: 10px auto;
    }
    #toronto-jobs, #montreal-jobs {
        display: none;
    }

}