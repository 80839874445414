.footer-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 20px;
    background-color: #e7e7e7;
    box-shadow: 0px 0px 20px 2px #00000047;
}
menu.footer-nav {
    padding: 0px;
    margin: 0 auto;
    text-align: center;
}
/* LOGOS */
.footer-logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-logo-wrapper row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
}
.footer-logo-wrapper row a {
    width: 25%;
}
.footer-logo-wrapper row img {
    width: calc(100% - 8px);
    border-radius: 10px;
    margin: 2px 4px;
    box-shadow: 0px 0px 3px #b7b7b7;
}
.footer-logo-wrapper row img:hover {
    box-shadow: 0px 0px 3px #484848;
}
/* Social Links */
.social-wrapper {
    width: fit-content;
    margin: 20px auto 10px;
    font-size: 48px;
}
.social-wrapper svg {
    color: #000000;
}

.social-wrapper svg:hover {
    color: var(--salsa-sky-blue);
}
.footer-nav span {
    font-size: max(1.2vw, 18px);
}
.nav-item {
    font-size: max(1.2vw, 18px);
    font-weight: 400;
    padding: 5px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.nav-item:hover {
    color: var(--salsa-sky-blue);
}

@media screen and (max-width: 580px) {
    .footer-wrapper {
        padding: 20px;
    }
    .footer-logo-wrapper row img {
        width: calc(100% - 6px);
        margin: 1px 3px;
    }
    .social-wrapper {
        margin: 10px auto 5px;
    }
    .nav-item, .footer-nav span {
        font-size: max(1.2vw, 16px);
    }
}