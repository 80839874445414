.about-page-banner {
    padding-top: 31.6%;
    background-position: center;
    background-size: contain;
    background-image: url(../Media/Slides/About_Slider/MissionandVisionSlider.webp);
}
.highlight {
    font-family: 'Lemonmilk-Bold';
    color: var(--salsa-orange);
}
.about-description-wrapper {
    padding: 10px 20px;
    border-left: 5px solid var(--salsa-orange);
    background: #ebf1f5;
}

.value-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.value-card {
    width: calc(33% - 40px);
    min-height: 220px;
    max-width: 390px;
    padding: 20px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgb(205 205 205);
}
.value-icon {
    font-size: 30px;
    color: var(--salsa-orange);
}
h1.value-title {
    font-family: 'sofia-pro';
    margin: 15px auto;
}
.value-card-info-wrappper {
    text-align: center;
}
p.value-description {
    margin: 0px;
    text-align: center;
}

@media screen and (max-width: 950px) {
    .value-card {
        width: calc(50% - 40px);
        padding: 15px;
        margin: 10px 0px;
    }
}
@media screen and (max-width: 768px) {
    .value-card {
        width: calc(50% - 20px);
        padding: 15px;
    }
}
@media screen and (max-width: 580px) {
    .value-card-wrapper {
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin: auto -10px;
        padding: 5px 20px 20px;
        overflow-x: scroll;
        scroll-snap-type: x;
    }
    .value-card {
        width: fit-content;
        min-height: 220px;
        margin: 0px 10px;
        scroll-snap-align: center;
    }
    p.value-description {
        width: 60vw;
    }
}

.timeline {
    width: fit-content;
    padding: 0px;
    margin: -20px auto 20px;
    list-style: none;
}
.timeline-info-wrapper {
    padding: 40px 20px 20px;
    background: #ebf1f5;
}
.timeline-date {
    position: relative;
    top: 20px;
    width: 310px;
    padding: 10px;
    margin: 0px;
    text-align: center;
    border-radius: 0px 20px;
    color: white;
    background: var(--salsa-orange);
    box-shadow: 0px 0px 8px rgb(205 205 205);
}
.timeline-title {
    font-weight: 500;
}