.post-template {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}
.post-wrapper {
    width: 70%;
    padding-right: 20px;
}
.post-job-title {
    min-width: 300px;
    padding: 10px;
    margin: 0;
    text-align: center;
    border-radius: 0px 20px;
    font-weight: 500;
    color: white;
    background: var(--salsa-orange);
    box-shadow: 0px 0px 8px rgb(205 205 205);
}

.post-content-wrapper {
    padding: 10px 20px 10px 20px;
    border-left: 5px solid var(--salsa-orange);
    background: #ebf1f5;
}

.post-content-wrapper ul {
    padding-left: 20px;
}

#application-wrapper-desktop {
    position: sticky;
    top: 20px;
    height: fit-content;
    width: 30%;
    max-width: 320px;
    padding: 25px 25px 5px;
    border-radius: 10px;
    background: #ebf1f5;
    box-shadow: 0px 0px 8px rgb(205 205 205);
}
.application-title {
    font-size: 26px !important;
    margin: 0px auto 20px !important;
    width: fit-content;
}
.application-instruction {
    margin: 0px auto 20px;
    max-width: 310px;
    font-size: 16px;
    text-align: center;
}
.application-instruction a {
    cursor: pointer;
}
.details-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.detail-title {
    width: 40%;
    margin: 5px 0px 10px;
    font-size: 16px;
}
.detail-item {
    width: calc(60% - 10px);
    margin: 0 0 10px;
    font-size: 16px;
}
#apply-button {
    text-decoration: none;
}
#apply-button p {
    margin: 20px auto 30px;
    width: 80%;
    padding: 5px 20px 10px;
    color: white;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    background: var(--salsa-orange);
}
#apply-button p:hover {
    background: #c94202;
}

/*Mobile Section*/
.mobile-post-job-title {
    display: none;
    position: relative;
    z-index: 10;
    min-width: 300px;
    padding: 15px;
    margin: -20px -20px 0px;
    text-align: center;
    font-weight: 500;
    color: white;
    background: var(--salsa-orange);
}

#application-mobile-header {
    display: none;
    padding: 20px;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -20px;
    background: #ebf1f5;
}
#application-mobile-button-wrapper {
    display: none;
    position: sticky;
    top: 0px;
    padding: 30px 0px;
    margin-right: -20px;
    margin-left: -20px;
    background: #ebf1f5;
}
#application-mobile-footer {
    display: none;
    padding: 0px 20px 0px 20px;
    margin-right: -20px;
    margin-left: -20px;
    background: #ebf1f5;
}

#application-mobile-button-wrapper #apply-button p {
    margin: 0px auto;
}

@media screen and (max-width: 980px) {
    .post-template {
        flex-direction: column;
        margin-top: 0px;
    }
    .post-wrapper {
        width: 100%;
        padding-right: 0px;
    }
    .mobile-post-job-title {
        display: block;
    }
    .post-job-title, #application-wrapper-desktop {
        display: none;
    }
    #application-mobile-header, #application-mobile-button-wrapper, #application-mobile-footer {
        display: block !important;
    }
    p.application-instruction {
        max-width: 500px;
    }
    .details-wrapper {
        max-width: 600px;
        margin: auto;
    }
    .details-column-two {
        text-align: end;
    }
    #apply-button p {
        max-width: 300px;
    }
    p.application-instruction {
        max-width: 600px;
    }
    div#job-posting-template {
        flex-direction: column;
        
    }   
}

@media screen and (max-width: 580px) {
    .mobile-post-job-title {
        margin-top: -10px;
    }
    .mobile-post-job-title, #application-mobile-header, #application-mobile-button-wrapper, #application-mobile-footer {
        margin-right: -10px !important;
        margin-left: -10px !important;
    }
}