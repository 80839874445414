.get-involved-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: 40px auto;
}

.get-involved-wrapper a {
    width: 45%;
    text-decoration: none;
}

.get-involved-wrapper a:hover {
    .learn-more-wrapper p{
        color: var(--salsa-orange);
    }
    .volunteer-wrapper svg, .careers-wrapper svg {
        color: rgb(70, 70, 70);
    }
}

.volunteer-wrapper, .careers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
    text-align: center;
    box-shadow: 0px 0px 8px rgb(205 205 205);
}

h1.primary-title {
    margin: auto;
    font-size: 60px;
    color: black;
}
.secondary-title {
    margin: auto;
    font-size: 30px;
    font-weight: 400;
    color: var(--salsa-orange);
}
.learn-more-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.learn-more-wrapper seperator {
    width: 25%;
}
.learn-more-wrapper p{
    width: 50%;
    padding: 10px 5px;
    font-weight: 500;
    font-size: 18px;
    color: black;
}


.volunteer-wrapper svg, .careers-wrapper svg {
    font-size: 80px;
    color: var(--salsa-orange);
}

@media screen and (max-width: 980px) {
    .primary-title {
        font-size: 45px !important;
    }
}

@media screen and (max-width: 768px) {
    .get-involved-wrapper {
        flex-direction: column;
        margin: 0px auto;
    }
    .get-involved-wrapper a {
        width: 100%;
    }
    .volunteer-wrapper, .careers-wrapper {
        margin: 20px auto;
    }
}