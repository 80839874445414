.eventCard {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    max-width: 1400px;
    min-height: 230px;
    box-shadow: 1px 2px 10px #cbcbcb;
    cursor: pointer;
    overflow: hidden;
}
.event-info {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 50px 20px 20px;
    overflow: hidden;
}
.event-info h2 {
    font-size: 2em;
    margin-top: 0px;
    margin-bottom: 10px;
    color: var(--salsa-orange);
}
.event-info p {
    font-size: 18px;
}
.event-prompt-wrapper {
    display: flex;
    flex-direction: row;
}
.event-prompt {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    background: var(--salsa-summer-blue);
}
.event-hightlights {
    width: 100px;
    height: 100%;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: var(--salsa-summer-blue);
    cursor: pointer;
}
.event-link {
    display: flex;
    align-items: center;
    width: 100px;
    height: 100%;
    text-decoration: none;
}
.event-link span {
    display: block;
    margin: auto;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.highlights {
    position: relative;
    z-index: 1;
    border-right: 1px solid white;
}

.event-tab-wrapper {
    position: absolute;
    left: -45px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    height: fit-content;
    font-size: 60px;
    color: var(--salsa-summer-blue);
    pointer-events: none;
}

.event-prompt:hover .event-tab-wrapper {
    color: var(--salsa-sky-blue);
}

.event-prompt:hover, .event-hightlights:hover {
    background: var(--salsa-sky-blue);
}

/* Lightbox Style */
.lightbox-content {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
}
iframe {
    width: 80vw;
    max-width: 1200px;
    height: calc(80vw /1.8);
    max-height: calc(1200px / 1.8);;
}
span.close-button {
    color: white;
    font-size: 40px;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .eventCard {
        flex-direction: column;
        justify-content: space-between;
        margin: 20px auto;
        min-height: 330px;
    }
    .event-info {
        margin: auto;
        padding: 20px;
    }
    .event-tab-wrapper {
        display: none;
    }
    .event-prompt {
        width: 50%;
    }
    .event-hightlights, .event-link {
        width: 100%;
    }

    iframe {
        width: 95vw;
        height: calc(95vw /1.8);
    }
}