.sfs-page-banner {
    padding-top: 31.5%;
    background-position: center;
    background-size: contain;
    background-image: url(../Media/Slides/SalsaForSeniors_Slider/SalsaForSeniors_Slider_1.webp);
}

.location-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: calc(50% - 10px);
    padding: 20px;
    text-align: center;
}
.location img {
    width: 80%;
    max-width: 350px;
}


section#school-section {
    padding: 20px;
    background: var(--salsa-summer-blue);
}

#school-section h1 {
    max-width: 1400px;
    margin: auto auto 20px;
    color: white;
}
.school-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: auto;
}

.school {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: calc((100% / 2) - 10px);
    min-height: 200px;
    margin-bottom: 20px;
}
.school-link {
    width: 40%;
    min-width: 280px;
}
.school img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -2px #000000b0;
}
.school p {
    width: 60%;
    padding-left: 20px;
    color: white;
}

/*******************************/
/**********Events*************/
/*******************************/
.event-card {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.date-wrapper {
    width: 150px;
    text-align: center;
    padding: 0px 20px;
    margin: 10px auto;
    border-right: 5px var(--salsa-summer-blue) solid;
}
.date-wrapper h2 {
    margin: 10px auto 10px;
}

.event-details {
    width: calc(100% - 150px);
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.event-time, .event-address {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.seniors-event-info {
    width: 100%;
}
.event-time svg, .event-address svg {
    margin-right: 10px;
}
.event-time p, .event-address p{
    margin: 10px 0px;
    width: 90%;
}
.event-rsvp-btn {
    width: 100%;
    padding: 15px;
    color: white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: #2a0073;
}
a.event-rsvp-btn:hover {
    background: var(--salsa-sky-blue);
}

@media screen and (max-width: 1200px) {
    .school {
        flex-direction: column;
        margin: 0px 0px 40px;
    }
    .school-link {
        width: 100%;
        min-width: 0px;
    }
    .school p {
        width: 100%;
        padding-left: 0px;
        margin: 20px auto auto;
        text-align: center;
    }
}
@media screen and (max-width: 768px) {
    .location-wrapper {
        flex-direction: column;
    }
    .location {
        width: 100%;
        min-height: 300px;
        margin-bottom: 20px;
        box-shadow: none;
    }
}
@media screen and (max-width: 580px) {
    .location {
    padding: 0px 20px;
    }
    section#school-section {
        padding: 10px;
    }
    #school-section h1 {

    }
    .school {
        width: 100%;
        margin: 0px 0px 30px;
    }
    .school p {
        margin: 10px auto auto;
    }
    .event-card {
        margin-bottom: 20px;
    }
    .date-wrapper {
        width: 100px;
        padding: 10px 15px;
        margin: 0px;
    }
    .event-details {
        width: calc(100% - 100px);
        padding-left: 15px;
    }
    a.event-rsvp-btn {
        margin-top: 15px;
    }

}