.slider-container {
    position: relative;
    overflow: hidden;
}
.slide {
    flex: 0 0 100%;
  }
.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}
.slide img {
    width: 100%;
    height: auto;
}

button.prev, button.next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 3.5vw;
    width: 3.5vw;
    max-width: 80px;
    max-height: 80px;
    font-size: 2.5vw;
    border-radius: 50px;
    background-color: #333333a8;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
}
button.prev {
    left: 10px;
}
button.next {
    right: 10px;
}

button.prev:hover, button.next:hover {
    background-color: #333333d6;
}

.pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    border: 0;
    display: flex;
    justify-content: center;
}

.dot {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    border-radius: 100%;
    border: 1px solid white;
    background-color: #bbbbbb85;
    cursor: pointer;
}
.dot.active {
    background-color: #333; /* Active dot color */
}

@media screen and (max-width: 1100px) {
    button.prev, button.next {
        height: 4.5vw;
        width: 4.5vw;
        font-size: 3.5vw;
    }
    .dot {
        width: 10px;
        height: 10px;
    }
}
@media screen and (max-width: 768px) {
    button.prev, button.next {
        display: none;
    }
}
@media screen and (max-width: 580px) {
    .slider-container {
        margin-bottom: 20px;
    }
}